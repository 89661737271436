import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

function FormHeading(props) {
  return (
    <HeadingContainer {...props}>
      <p className="description">{props.$description}</p>
      <p className="main-heading">{props.$heading}</p>
    </HeadingContainer>
  );
}

const HeadingContainer = styled.div`
  text-transform: uppercase;
  letter-spacing: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  z-index: 9;

  .description {
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.$descriptionsize};
    font-weight: 500;
    text-align: center;
  }

  .main-heading {
    color: ${(props) => props.theme.colors.white};
    font-size: ${(props) => props.$headingsize};
    font-weight: 700;
    text-align: center;
  }

  @media only screen and (max-width: 640px) {
    gap: 8px;

    .description {
      font-size: 12px;
      line-height: 15px;
    }

    .main-heading {
      font-size: 20px;
      line-height: 24px;
    }
  }
`;

FormHeading.propTypes = {
  $description: PropTypes.string,
  $heading: PropTypes.string,
  $descriptionsize: PropTypes.string,
  $headingsize: PropTypes.string,
};

FormHeading.defaultProps = {
  $description: "hello there!",
  $heading: "welcome to the quiz!",
  $descriptionsize: "24px",
  $headingsize: "48px",
};

export default FormHeading;
