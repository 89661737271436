import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

function Button(props) {
  return (
    <ButtonContainer
      onClick={props.onClick}
      disabled={props.disabled}
      {...props}
    >
      {props.label}

      {props.type === "start" && (
        <span className="icon">
          <svg
            width="14"
            height="18"
            viewBox="0 0 14 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.20046 17.4659L5.19215 17.4625L5.18372 17.4593C5.09992 17.4279 4.96728 17.3549 4.85109 17.178C4.73335 16.9987 4.60815 16.6753 4.60815 16.1025V10.7275V10.2275H4.10815H2.15815C1.68065 10.2275 1.40115 10.1163 1.23876 10.0091C1.07677 9.90221 0.996209 9.77607 0.955206 9.68765C0.914478 9.59577 0.873363 9.45212 0.900204 9.2603C0.927177 9.06754 1.0286 8.78426 1.34267 8.42351C1.34293 8.4232 1.3432 8.4229 1.34347 8.42259L7.65013 1.25783L7.65031 1.25763C8.02724 0.82896 8.33334 0.669355 8.53909 0.612574C8.74198 0.55658 8.88943 0.58882 8.97426 0.620632C9.05805 0.652054 9.1907 0.725007 9.30689 0.901932C9.42462 1.08121 9.54982 1.4046 9.54982 1.97747V7.35247V7.85247H10.0498H11.9998C12.4773 7.85247 12.7568 7.96367 12.9192 8.07083C13.0812 8.17772 13.1617 8.30383 13.2028 8.39225C13.2435 8.48413 13.2846 8.6278 13.2578 8.81963C13.2308 9.01238 13.1294 9.29563 12.8154 9.65633C12.8151 9.65667 12.8148 9.65701 12.8145 9.65734L6.50784 16.8221L6.50715 16.8229C5.98512 17.4184 5.59639 17.5025 5.40815 17.5025C5.30953 17.5025 5.24143 17.483 5.20046 17.4659Z"
              fill="#090a11"
              stroke=""
            />
          </svg>
        </span>
      )}

      {props.type === "try" && (
        <span className="icon">
          <svg
            width="14"
            height="18"
            viewBox="0 0 14 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.20046 17.4659L5.19215 17.4625L5.18372 17.4593C5.09992 17.4279 4.96728 17.3549 4.85109 17.178C4.73335 16.9987 4.60815 16.6753 4.60815 16.1025V10.7275V10.2275H4.10815H2.15815C1.68065 10.2275 1.40115 10.1163 1.23876 10.0091C1.07677 9.90221 0.996209 9.77607 0.955206 9.68765C0.914478 9.59577 0.873363 9.45212 0.900204 9.2603C0.927177 9.06754 1.0286 8.78426 1.34267 8.42351C1.34293 8.4232 1.3432 8.4229 1.34347 8.42259L7.65013 1.25783L7.65031 1.25763C8.02724 0.82896 8.33334 0.669355 8.53909 0.612574C8.74198 0.55658 8.88943 0.58882 8.97426 0.620632C9.05805 0.652054 9.1907 0.725007 9.30689 0.901932C9.42462 1.08121 9.54982 1.4046 9.54982 1.97747V7.35247V7.85247H10.0498H11.9998C12.4773 7.85247 12.7568 7.96367 12.9192 8.07083C13.0812 8.17772 13.1617 8.30383 13.2028 8.39225C13.2435 8.48413 13.2846 8.6278 13.2578 8.81963C13.2308 9.01238 13.1294 9.29563 12.8154 9.65633C12.8151 9.65667 12.8148 9.65701 12.8145 9.65734L6.50784 16.8221L6.50715 16.8229C5.98512 17.4184 5.59639 17.5025 5.40815 17.5025C5.30953 17.5025 5.24143 17.483 5.20046 17.4659Z"
              fill=""
              stroke=""
            />
          </svg>
        </span>
      )}

      {(props.type === "continue" || props.type === "submit") && (
        <span className="icon">
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 7.38835V4.71835C1 1.40835 3.35 0.048351 6.22 1.70835L8.53 3.04835L10.84 4.38835C13.71 6.04835 13.71 8.75835 10.84 10.4184L8.53 11.7584L6.22 13.0983C3.35 14.7283 1 13.3784 1 10.0584V7.38835Z"
              fill="#090a11"
              stroke=""
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      )}

      {props.type === "screenshot" && (
        <span className="icon">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.3667 18.9577H5.6334C3.30006 18.9577 1.81673 17.566 1.6834 15.241L1.25006 8.36602C1.1834 7.32435 1.54173 6.32435 2.2584 5.56602C2.96673 4.80768 3.96673 4.37435 5.00006 4.37435C5.26673 4.37435 5.52506 4.21602 5.65006 3.96602L6.25006 2.77435C6.74173 1.79935 7.97506 1.04102 9.05006 1.04102H10.9584C12.0334 1.04102 13.2584 1.79935 13.7501 2.76602L14.3501 3.98268C14.4751 4.21602 14.7251 4.37435 15.0001 4.37435C16.0334 4.37435 17.0334 4.80768 17.7417 5.56602C18.4584 6.33268 18.8167 7.32435 18.7501 8.36602L18.3167 15.2493C18.1667 17.6077 16.7251 18.9577 14.3667 18.9577ZM9.05006 2.29102C8.4334 2.29102 7.65006 2.77435 7.36673 3.33268L6.76673 4.53268C6.41673 5.20768 5.74173 5.62435 5.00006 5.62435C4.30006 5.62435 3.65006 5.90768 3.16673 6.41602C2.69173 6.92435 2.45006 7.59102 2.50006 8.28268L2.9334 15.166C3.0334 16.8493 3.94173 17.7077 5.6334 17.7077H14.3667C16.0501 17.7077 16.9584 16.8493 17.0667 15.166L17.5001 8.28268C17.5417 7.59102 17.3084 6.92435 16.8334 6.41602C16.3501 5.90768 15.7001 5.62435 15.0001 5.62435C14.2584 5.62435 13.5834 5.20768 13.2334 4.54935L12.6251 3.33268C12.3501 2.78268 11.5667 2.29935 10.9501 2.29935H9.05006V2.29102Z"
              fill="#090a11"
            />
            <path
              d="M11.25 7.29102H8.75C8.40833 7.29102 8.125 7.00768 8.125 6.66602C8.125 6.32435 8.40833 6.04102 8.75 6.04102H11.25C11.5917 6.04102 11.875 6.32435 11.875 6.66602C11.875 7.00768 11.5917 7.29102 11.25 7.29102Z"
              fill="#090a11"
            />
            <path
              d="M10.0003 15.6257C8.15866 15.6257 6.66699 14.134 6.66699 12.2923C6.66699 10.4507 8.15866 8.95898 10.0003 8.95898C11.842 8.95898 13.3337 10.4507 13.3337 12.2923C13.3337 14.134 11.842 15.6257 10.0003 15.6257ZM10.0003 10.209C8.85033 10.209 7.91699 11.1423 7.91699 12.2923C7.91699 13.4423 8.85033 14.3757 10.0003 14.3757C11.1503 14.3757 12.0837 13.4423 12.0837 12.2923C12.0837 11.1423 11.1503 10.209 10.0003 10.209Z"
              fill="#090a11"
            />
          </svg>
        </span>
      )}

      {props.type === "try-again" && (
        <span className="icon">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.1253 18.0577C15.7003 17.116 18.3337 13.866 18.3337 9.99935C18.3337 5.39935 14.6337 1.66602 10.0003 1.66602C4.44199 1.66602 1.66699 6.29935 1.66699 6.29935M1.66699 6.29935V2.49935M1.66699 6.29935H3.34199H5.36699"
              stroke=""
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1.66699 10C1.66699 14.6 5.40033 18.3333 10.0003 18.3333"
              stroke=""
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray="3 3"
            />
          </svg>
        </span>
      )}
    </ButtonContainer>
  );
}

const ButtonContainer = styled.button`
  border: 1px solid ${(props) => props.theme.colors.white};
  font-size: 16px;
  font-weight: 600;
  background-color: ${(props) => props.theme.colors.secondary};
  color: ${(props) => props.theme.colors.white};
  border-radius: 50px;
  padding: 10px 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  white-space: nowrap;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  width: fit-content;

  .icon {
    display: flex;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;

    svg {
      stroke: ${(props) => props.theme.colors.white};
      transition: all 0.3s ease-out;
      -webkit-transition: all 0.3s ease-out;
    }
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.secondary};
    border-color: ${(props) => props.theme.colors.primary};

    .icon {
      svg {
        stroke: ${(props) => props.theme.colors.secondary};
      }
    }
  }

  ${(props) =>
    props.$filled &&
    css`
      background-color: ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.secondary};
      border: 1px solid ${(props) => props.theme.colors.primary};
      cursor: pointer;
      padding: 14px 28px;
      transition: all 0.3s ease-out;
      -webkit-transition: all 0.3s ease-out;

      .icon {
        svg {
          stroke: ${(props) => props.theme.colors.secondary};
          fill: ${(props) => props.theme.colors.primary};
        }
      }

      &:hover {
        outline: 6px solid #e5fe4033;
        padding: 16px 32px;
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}

  @media only screen and (max-width: 640px) {
    width: 100%;
    padding: 18px 0;
  }
`;

Button.propTypes = {
  type: PropTypes.string,
};

Button.defaultProps = {
  type: "continue",
};

export default Button;
