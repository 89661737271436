import React, { useState, useEffect, useRef } from "react";
import { profanityCheck } from "../util/ProfanityCheck";
import styled from "styled-components";
import Input from "./Input";
import FormHeading from "./FormHeading";
import Avatar from "./Avatar";
import { userAvatarsOptions } from "../assets/avatars/AvatarList";
import { isEmpty } from "../util/Functions";
import { VALIDATE_USERNAME_ENDPOINT } from "../util/ApiEndpoint";
import Button from "./Button";
import Header from "./Header";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Background from "./Background";

const UserName = ({ userInfo, updateUsername }) => {
  const [userName, setUserName] = useState(userInfo.username);
  const [avatarId, setAvatarId] = useState(userInfo.avatar_id);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const scrollableUserRef = useRef();

  useEffect(() => {
    if (scrollableUserRef.current) {
      window.scroll(0, -80);
    }
  }, []);

  const onInputChange = (e) => {
    if (e.target.value === "") {
      setErrorMessage(null);
      setUserName(e.target.value);
    }
    if (e.target.value.length > 25) {
      return setErrorMessage("Please choose a smaller username.");
    } else {
      setErrorMessage(null);
      setUserName(e.target.value);
    }
  };

  const onContinue = async () => {
    const isProfane = profanityCheck(userName);
    if (isEmpty(userName)) {
      return setErrorMessage(
        "Please enter your username and select an avatar to proceed"
      );
    }
    if (isProfane) {
      // Profanity detected
      return setErrorMessage(
        "Profane words not allowed. Please use different word to proceed."
      );
    }

    validateUserName();
  };

  const validateUserName = async () => {
    setLoading(true);
    await fetch(
      `${VALIDATE_USERNAME_ENDPOINT}?username=${userName}&email=${userInfo.email}`,
      {
        method: "get",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (!data.unique_user) {
          return setErrorMessage(
            "This username is already in use. Please enter a different username."
          );
        } else {
          setErrorMessage(null);
          updateUsername(userName, avatarId);
        }
      })
      .catch((err) => {
        console.log("Error--", err);
        setLoading(false);
        return setErrorMessage(
          "Username validation failed. Try again after sometime."
        );
      });
  };

  return (
    <>
      <Background grid={true} topGradient={true} objposition="opaque" />

      <UserNameContainer ref={scrollableUserRef}>
        <Header isLoggedIn="false" />

        <FormHeading
          $description="How would you like to appear on the leaderboard?"
          $descriptionsize="14px"
          $heading="choose your identity"
          $headingsize="40px"
          style={{ marginTop: "100px" }}
          showentermessage={"true"}
        />

        <Spin
          spinning={loading}
          style={{
            zIndex: 999,
          }}
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 50,
                color: "#E5FE40",
              }}
            />
          }
        />

        {!loading && (
          <>
            <Input
              value={userName}
              onChange={onInputChange}
              placeholder="Enter Username"
              spellCheck="false"
              showentermessage={"false"}
            />
            <div className="avatar-container">
              {userAvatarsOptions.map((avatar) => (
                <label
                  className="avatar"
                  key={avatar.id}
                  htmlFor={avatar.avatar}
                >
                  <Avatar
                    $useravatar={avatar.id + ""}
                    $usercolor={avatar.bgColor}
                    size="72px"
                  />
                  <input
                    type="radio"
                    name="avatar"
                    id={avatar.avatar}
                    checked={avatar.id === avatarId}
                    onChange={() => setAvatarId(avatar.id)}
                  />
                  <svg
                    width=""
                    height=""
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10 1.45557C11.6068 5.3193 14.6807 8.39318 18.5444 10C14.6807 11.6068 11.6068 14.6807 10 18.5444C8.39318 14.6807 5.3193 11.6068 1.45557 10C5.3193 8.39318 8.39318 5.3193 10 1.45557Z" />
                  </svg>
                </label>
              ))}
            </div>

            <div style={{ color: "red", zIndex: 999 }}>
              {errorMessage ? errorMessage : null}
            </div>

            <div className="continue-btn">
              <Button
                label="Continue"
                type="continue"
                disabled={loading}
                onClick={onContinue}
              />
            </div>
          </>
        )}
      </UserNameContainer>
    </>
  );
};

const UserNameContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  gap: 48px;
  padding: 0 20px;
  z-index: 999;
  overflow: scroll;

  height: 100vh;
  width: 100vw;

  .avatar-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 8px;
    z-index: 9;
    margin-bottom: 100px;

    .avatar input:checked + svg {
      fill: ${(props) => props.theme.colors.primary};
      stroke: ${(props) => props.theme.colors.primary};
    }

    .avatar {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      border-radius: 20px;
      padding: 20px;
      cursor: pointer;
      transition: all 0.3s ease-out;
      -webkit-transition: all 0.3s ease-out;

      svg {
        stroke: white;
        transition: all 0.3s ease-out;
        -webkit-transition: all 0.3s ease-out;
        height: 20px;
        width: 20px;
      }

      &:hover {
        background-color: #15161d;
        outline: 1px solid ${(props) => props.theme.colors.primary};

        svg {
          stroke: ${(props) => props.theme.colors.primary};
          fill: ${(props) => props.theme.colors.primary};
        }
      }
    }
  }

  input[type="radio"] {
    display: none;
  }

  .continue-btn {
    z-index: 99;
  }

  @media only screen and (max-width: 640px) {
    .avatar-container {
      .avatar {
        padding: 12px;
        border-radius: 16px;

        svg {
          height: 12px;
          width: 12px;
        }
      }
    }
    .continue-btn {
      width: calc(100% - 32px);
      position: fixed;
      bottom: 20px;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 90vw;
  }
`;

export default UserName;
