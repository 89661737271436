import React from "react";
import styled from "styled-components";
// import { useScreenshot, createFileName } from "use-react-screenshot";
import Header from "./Header";
import Profile from "./Profile";
import Button from "./Button";
import Background from "./Background";
import passed from "../assets/images/passed.png";
import validityTimer from "../assets/images/validity.svg";
import ticket from "../assets/images/ticket-bg.svg";
import obj1 from "../assets/objects/obj-1.svg";
import obj2 from "../assets/objects/obj-2.svg";
import obj3 from "../assets/objects/obj-3.svg";
import obj4 from "../assets/objects/obj-4.svg";
import obj5 from "../assets/objects/obj-5.svg";
import obj6 from "../assets/objects/obj-6.svg";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const ResultPass = ({ username, avatar_id, score, time, onTryAgain, load }) => {
  // const ref = createRef(null);
  // const [screenshot, takeScreenShot] = useScreenshot({
  //   type: "image/jpeg",
  //   quality: 1.0,
  // });

  // TODO: Reset local storage

  const tryAgain = () => {
    onTryAgain();
    // onGetStartedClick();
  };

  // const download = (
  //   image,
  //   { name = "quiz-result", extension = "jpg" } = {}
  // ) => {
  //   const a = document.createElement("a");
  //   a.href = image;
  //   a.download = createFileName(extension, name);
  //   a.click();
  // };

  // const onTakeScreenshot = () => {
  //   return takeScreenShot(ref.current).then(download);
  // };

  return (
    <>
      <Background grid={true} objposition="hide" />

      <ResultPassContainer>
        <Header username={username} avatar_id={avatar_id} isLoggedIn="true" />
        <Spin
          spinning={load}
          style={{
            position: "absolute",
            zIndex: 999,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          indicator={
            <LoadingOutlined style={{ fontSize: 50, color: "#E5FE40" }} />
          }
        />
        <div className="result-container">
          <div className="result-content">
            <div className="content">
              <span className="heading">
                <h4>You made it! 🎉</h4>
                <p>What's your next move?</p>
              </span>
              <span className="message">
                <p>
                Snap a screenshot and visit our booth for a token to play our prize-filled claw machine! 
                For higher leaderboard rankings or more claw machine plays, keep playing!
                </p>
                
              </span>
            </div>
            <div className="try-btn">
              <Button label="Try again" type="try-again" onClick={tryAgain} />
            </div>
          </div>
          <div className="result-ticket">
            <div className="bg-shadow"></div>

            <img className="obj obj-1" src={obj1} alt="object" />
            <img className="obj obj-2" src={obj2} alt="object" />
            <img className="obj obj-3" src={obj3} alt="object" />
            <img className="obj obj-4" src={obj4} alt="object" />
            <img className="obj obj-5" src={obj5} alt="object" />
            <img className="obj obj-6" src={obj6} alt="object" />

            <div className="ticket">
              <div className="ticket-bg">
                <img src={ticket} alt="pass" />
              </div>
              <span className="score-container">
                <span className="heading">
                  <img height="88px" width="72px" src={passed} alt="badge" />
                  <p>
                    Congratulations! <br /> You scored big!
                  </p>
                </span>
                <span className="score">
                  <h6>your score</h6>
                  <p>{score}</p>
                </span>
              </span>

              <span className="profile">
                <Profile $useravatar={avatar_id} username={username} />
                <p className="validity">
                  <img src={validityTimer} alt="validity" /> Valid till {time}
                </p>
              </span>
            </div>
            {/* <span className="screenshot">
              <Button
                type="screenshot"
                label="Take screenshot"
                $filled="true"
                onClick={onTakeScreenshot}
              />
            </span> */}
          </div>
        </div>
      </ResultPassContainer>
    </>
  );
};

const ResultPassContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  z-index: 9;
  padding: 0 32px;
  color: ${(props) => props.theme.colors.white};

  height: 100vh;
  width: 100vw;
  overflow-y: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  .result-container {
    display: flex;
    gap: 160px;

    margin: 120px 0;

    .result-content {
      display: flex;
      flex-direction: column;
      gap: 48px;
      padding-top: 60px;

      .content {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .heading {
          font-weight: 700;
          font-size: 44px;
          line-height: 52px;

          h4 {
            color: ${(props) => props.theme.colors.primary};
          }
        }

        .message {
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;
          color: ${(props) => props.theme.colors.grey};

          display: flex;
          flex-direction: column;
          gap: 48px;
          max-width: 30vw;
        }
      }

      .try-btn {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }

    .result-ticket {
      position: relative;
      width: fit-content;
      height: fit-content;

      .obj {
        position: absolute;
      }

      .obj-1 {
        top: 80%;
        left: -30%;
      }

      .obj-2 {
        top: 10%;
        left: -20%;
      }

      .obj-3 {
        top: -10%;
        left: 30%;
      }

      .obj-4 {
        top: 40%;
        left: 110%;
      }

      .obj-5 {
        top: 110%;
        left: 110%;
      }

      .obj-6 {
        top: 110%;
        left: 20%;
      }

      .screenshot {
        position: absolute;
        margin-top: 32px;
        left: 50%;
        transform: translate(-50%);
      }

      .bg-shadow {
        position: absolute;
        height: 270px;
        width: 320px;
        border-radius: 50%;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        filter: blur(100px);
        background-color: ${(props) => props.theme.colors.primary};
        z-index: -1;
      }

      .ticket {
        background-color: transparent;
        padding: 42px 30px;
        border-radius: 16px;
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: column;
        gap: 80px;

        .ticket-bg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: -1;
        }

        .score-container {
          color: ${(props) => props.theme.colors.secondary};
          display: flex;
          flex-direction: column;
          gap: 32px;
          z-index: 999;

          .heading {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 24px;
            text-align: center;

            p {
              font-weight: 600;
              font-size: 32px;
              line-height: 38px;
              white-space: nowrap;
            }
          }

          .score {
            text-align: center;
            text-transform: uppercase;
            font-weight: 600;

            h6 {
              font-size: 18px;
              line-height: 21px;
              color: ${(props) => props.theme.colors.grey};
            }

            p {
              font-size: 64px;
              line-height: 76px;
              color: ${(props) => props.theme.colors.success};
            }
          }
        }

        .profile {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 20px;

          .validity {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: ${(props) => props.theme.colors.error};
            display: flex;
            align-items: center;
            gap: 4px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .result-container {
      flex-direction: column-reverse;
      align-items: center;
      width: 100%;
      gap: 80px;
      margin: 80px 0;

      .result-content {
        padding-top: 0;

        .content {
          text-align: center;
          align-items: center;

          .message {
            max-width: 100%;
          }
        }

        .try-btn {
          justify-content: center;
        }
      }

      .result-ticket {
        margin-top: 60px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
  }

  @media only screen and (max-width: 640px) {
    .result-container {
      gap: 50px;

      .result-content {
        .content {
          .heading {
            font-size: 34px;
            line-height: 40px;
          }

          .message {
            font-size: 16px;
            gap: 10px;
          }
        }
      }

      .result-ticket {
        .obj {
          display: none;
        }

        .ticket {
          width: 300px;
          gap: 40px;

          .ticket-bg {
            img {
              width: 350px;
            }
          }

          .score-container {
            gap: 20px;
            .heading {
              img {
                height: 70px;
                width: 57px;
              }

              p {
                font-size: 24px;
              }
            }

            .score {
              h6 {
                font-size: 15px;
              }

              p {
                font-size: 55px;
              }
            }
          }
        }
      }
    }
  }
`;

export default ResultPass;
