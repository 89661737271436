import { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import styled from "styled-components";
import Header from "./Header";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Background from "./Background";
import { SUBMIT_ANSWERS_ENDPOINT } from "../util/ApiEndpoint";
import { encrypt } from "../util/Encrypt";

const Quiz = ({ questions, userInfo, resultChecking, timer }) => {
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(-1);
  const [userSelection, setUserSelection] = useState([]);
  const [remainingTime, setRemainingTime] = useState(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [timerKey, setTimerKey] = useState(0);
  const [loadView, setLoadView] = useState(false);

  const scrollableQueRef = useRef();
  const isSmallScreen = window.matchMedia('(max-width: 640px)').matches;

  useEffect(() => {
    if (scrollableQueRef.current) {
      window.scroll(0, -80);
    }
  }, [activeQuestion]);

  const onSubmitAnswers = (data) => {
    const body = {
      email: userInfo.email,
      username: userInfo.username,
      avatar_id: userInfo.avatar_id,
      questions: JSON.parse(data),
    };
    localStorage.removeItem("answers");
    submitAnswersAPI(body);
  };

  const handleNextQuestion = () => {
    // Reset selected answer
    setSelectedAnswerIndex(-1);

    // Check if quiz finished
    if (activeQuestion + 1 >= questions.length) {
      setLoadView(true);
      onSubmitAnswers(localStorage.getItem("answers"));
      return;
    }

    // Set next question
    setActiveQuestion((prev) => prev + 1);
    setIsPlaying(true);
    setTimerKey((prevKey) => prevKey + 1);
  };

  const submitAnswersAPI = async (body) => {
    await fetch(SUBMIT_ANSWERS_ENDPOINT, {
      method: "post",
      body: encrypt(JSON.stringify(body)),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        resultChecking(data);
      })
      .catch((err) => {
        console.log("Error--", err);
      });
  };

  const handleSelectAnswer = async (answerIndex = null) => {
    setIsPlaying(false);
    // Log Answer
    let tempUserSelection = [...userSelection];
    let questionObject = {
      question: questions[activeQuestion].question,
      answer_selected: answerIndex
        ? questions[activeQuestion].options[answerIndex - 1]
        : null,
      timer_value: answerIndex ? remainingTime : 0,
      difficulty: questions[activeQuestion].difficulty,
    };

    tempUserSelection.push(questionObject);
    localStorage.setItem("answers", JSON.stringify(tempUserSelection));
    setUserSelection(tempUserSelection);
    handleNextQuestion();
  };

  const timerContent = ({ remainingTime }) => {
    return `${remainingTime} secs`;
  };

  const { question, options } = questions[activeQuestion];
  const numberOfQuestions = questions.length;

  return (
    <>
      <Background grid={true} objposition="corner" />

      <>
        <Header
          username={userInfo.username}
          avatar_id={userInfo.avatar_id}
          isLoggedIn={"true"}
        />

        <motion.div
          variants={{
            initial: {
              background: "transparent",
              clipPath: "circle(0% at 50% 50%)",
            },
            animate: {
              background: "transparent",
              clipPath: "circle(100% at 50% 50%)",
            },
          }}
          style={{ zIndex: "9" }}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.5 }}
        >
          <Spin
            spinning={loadView}
            style={{
              position: "absolute",
              zIndex: 999,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            indicator={
              <LoadingOutlined style={{ fontSize: 50, color: "#E5FE40" }} />
            }
          />
          <QuizContainer ref={scrollableQueRef} style={{ pointerEvents: loadView ? "none" : "auto" }}>
            
            <div className="countdown">
              <CountdownCircleTimer
                size={isSmallScreen ? 50 : 80}
                strokeWidth={5}
                trailColor={"#23241a"}
                strokeLinecap={"square"}
                isPlaying={isPlaying}
                duration={timer}
                key={timerKey}
                colors={["#e5fe40"]}
                style={{ color: "black" }}
                children={(props) => timerContent(props)}
                onUpdate={(remainingTime) => setRemainingTime(remainingTime)}
                onComplete={() => handleSelectAnswer(null)}
              >
                {({ remainingTime }) => remainingTime}
              </CountdownCircleTimer>
            </div>

            <div className="question-container">
              <h4>
                question {activeQuestion + 1} / {numberOfQuestions}
              </h4>
              <p>{question}</p>
            </div>

            <div className="option-container">
              {options.length > 0
                ? options.map((option, index) => (
                    <label className="option" key={index}>
                      <input
                        type="radio"
                        name="option"
                        checked={false}
                        onClick={() => {
                          if (selectedAnswerIndex !== -1) {
                            return;
                          }
                          handleSelectAnswer(index + 1);
                        }}
                      />
                      <div className="option-content">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M10 1.45557C11.6068 5.3193 14.6807 8.39318 18.5444 10C14.6807 11.6068 11.6068 14.6807 10 18.5444C8.39318 14.6807 5.3193 11.6068 1.45557 10C5.3193 8.39318 8.39318 5.3193 10 1.45557Z" />
                        </svg>
                        <p>{option}</p>
                      </div>
                    </label>
                  ))
                : null}
            </div>
          </QuizContainer>
        </motion.div>
      </>
    </>
  );
};

const QuizContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  gap: 32px;
  color: ${(props) => props.theme.colors.white};
  width: 60vw;

  overflow-y: scroll;
  height: 100vh;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  .countdown {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.secondary};
    border-radius: 100px;
    margin: 0;
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .question-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    font-weight: 500;

    margin-top: 230px;

    h4 {
      font-size: 14px;
      line-height: 16px;
      color: ${(props) => props.theme.colors.primary};
      text-transform: uppercase;
    }

    p {
      font-size: 24px;
      line-height: 32px;
    }
  }

  .option-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    margin-bottom: 200px;

    .option {
      display: flex;
      width: 100%;

      input[type="radio"] {
        display: none;
      }

      .option-content {
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 23px 20px;
        border-radius: 20px;
        width: 100%;
        cursor: pointer;
        border: 1px solid transparent;
        transition: all 0.3s ease-out;

        svg {
          stroke: white;
          min-width: 20px;
        }
      }
    }

    .option input:checked + .option-content svg {
      fill: ${(props) => props.theme.colors.primary};
      stroke: ${(props) => props.theme.colors.primary};
    }

    .option input:checked + .option-content {
      border: 1px solid ${(props) => props.theme.colors.primary};
      background-color: #ffffff0d;
      color: ${(props) => props.theme.colors.primary};
    }
  }

  @media only screen and (min-width: 768px) {
    .option-container {
      .option-content {
        &:hover {
          background-color: #ffffff0d;
          color: ${(props) => props.theme.colors.primary};
          border: 1px solid ${(props) => props.theme.colors.primary};

          svg {
            stroke: ${(props) => props.theme.colors.primary};
            fill: ${(props) => props.theme.colors.primary};
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: 90vw;

    .option-container {
      .option-content {
        &:hover {
          background-color: transparent;
          color: ${(props) => props.theme.colors.white};
          border: 1px solid transparent;

          svg {
            stroke: ${(props) => props.theme.colors.white};
            fill: transparent;
          }
        }

        &:active {
          background-color: #ffffff0d;
          color: ${(props) => props.theme.colors.primary};
          border: 1px solid ${(props) => props.theme.colors.primary};

          svg {
            stroke: ${(props) => props.theme.colors.primary};
            fill: ${(props) => props.theme.colors.primary};
          }
        }
      }
    }
  }

  @media only screen and (max-width: 640px) {
    .question-container {
      margin-top: 150px;
      p {
        font-size: 20px;
      }
    }

    .option-container {
      .option-content {
        font-size: 18px;
      }
    }

    .countdown {
      top: 13%;
    }
  }
`;

export default Quiz;
