import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Background from "./Background";
import Header from "./Header";
import FormHeading from "./FormHeading";
import { short_title } from "../contents/Content";
import Button from "./Button";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { profanityCheck } from "../util/ProfanityCheck";

function UserDetails({ userInfo, updateUserInformation }) {
  const initialValues = {
    name: userInfo.name,
    company: userInfo.company,
    role: userInfo.job_title,
  };

  const scrollableUserRef = useRef();

  useEffect(() => {
    if (scrollableUserRef.current) {
      window.scroll(0, -80);
    }
  }, []);

  const handleSubmit = (values) => {
    // Handle form submission logic here
    updateUserInformation("user_details", values, "user_details");
  };

  const validate = (values) => {
    const errors = {};
    const isNameProfane = profanityCheck(values.name);
    const isCompanyProfane = profanityCheck(values.company);
    const isRoleProfane = profanityCheck(values.role);

    // Validate each field for being non-empty
    if (!values.name) {
      errors.name = "Please enter name";
    }
    if (isNameProfane) {
      // Profanity detected
      errors.name =
        "Profane words not allowed. Please use different word to proceed.";
    }
    if (!values.company) {
      errors.company = "Please enter company name";
    }
    if (isCompanyProfane) {
      // Profanity detected
      errors.company =
        "Profane words not allowed. Please use different word to proceed.";
    }
    if (!values.role) {
      errors.role = "Please enter your role";
    }
    if (isRoleProfane) {
      // Profanity detected
      errors.role =
        "Profane words not allowed. Please use different word to proceed.";
    }

    return errors;
  };

  return (
    <>
      <Background grid={true} topGradient={true} objposition="opaque" />
      <UserDetailsContainer ref={scrollableUserRef}>
        <Header isLoggedIn="false" />

        <FormHeading
          $description={short_title}
          $heading="Professional details"
          $headingsize="40px"
          $descriptionsize="14px"
          style={{ marginTop: "100px" }}
        />

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validate}
        >
          <Form className="user-form">
            <label className="input-label">
              Tell us your name?
              <Field type="text" name="name" placeholder="Enter your name" />
              <ErrorMessage
                style={{ color: "red" }}
                name="name"
                component="div"
              />
            </label>

            <div className="other-detail">
              <label className="input-label">
                Where do you work?
                <Field
                  type="text"
                  name="company"
                  placeholder="Enter your company name"
                />
                <ErrorMessage
                  style={{ color: "red" }}
                  name="company"
                  component="div"
                />
              </label>
              <label className="input-label">
                What is your role?
                <Field
                  type="text"
                  name="role"
                  placeholder="Enter your job title"
                />
                <ErrorMessage
                  style={{ color: "red" }}
                  name="role"
                  component="div"
                />
              </label>
            </div>

            <div className="submit-btn">
              <Button
                label="Continue"
                type="submit"
                // style={{ marginBottom: "80px" }}
              />
            </div>
          </Form>
        </Formik>
      </UserDetailsContainer>
    </>
  );
}

const UserDetailsContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  /* border: 1px solid blue; */
  padding: 0 16px;
  width: 758px;
  z-index: 99;
  height: 100vh;
  overflow-y: scroll;

  .user-form {
    width: 100%;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    gap: 48px;

    .input-label {
      color: ${(props) => props.theme.colors.primary};
      display: flex;
      flex-direction: column;
      gap: 8px;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      width: 100%;
    }

    input {
      border: 1px solid #42452c;
      background-color: #0a0b12;
      padding: 24px 20px;
      border-radius: 16px;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #fff;

      &:focus,
      &:active {
        border: none;
        outline: 1px solid ${(props) => props.theme.colors.primary};
      }
    }

    input::placeholder {
      color: #42452c;
    }

    .other-detail {
      /* border: 1px solid blue; */
      display: flex;
      gap: 48px;
    }

    .submit-btn {
      margin-top: 50px;
      /* border: 1px solid red; */
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media only screen and (max-width: 640px) {
    .user-form {
      .other-detail {
        flex-direction: column;
      }

      .submit-btn {
        width: calc(100% - 32px);
        position: fixed;
        bottom: 20px;
      }
    }
  }
`;

export default UserDetails;
