import React from "react";
import styled from "styled-components";
import rightArrow from "../assets/images/right-arrow.svg";
import leftArrow from "../assets/images/left-arrow.svg";

function HomeButton(props) {
  return (
    <ButtonContainer
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <span className="arrow left-side-arrow">
        <img src={rightArrow} alt="arrow" />
        <img src={rightArrow} alt="arrow" />
      </span>
      <p>Let's Get Started</p>
      <span className="arrow right-side-arrow">
        <img src={leftArrow} alt="arrow" />
        <img src={leftArrow} alt="arrow" />
      </span>
    </ButtonContainer>
  );
}

const ButtonContainer = styled.button`
  display: flex;
  align-items: center;
  /* gap: 48px; */
  background-color: ${(props) => props.theme.colors.primary};
  color: #657110;
  outline: 10px solid rgba(243, 252, 181, 0.12);
  padding: 32px;
  border-radius: 24px;
  border: 0;
  font-size: 28px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.5s ease-out;
  z-index: 9;
  white-space: nowrap;

  .arrow {
    display: flex;
    align-items: center;
    width: 50px;
    transition: all 0.5s ease-out;
  }

  .left-side-arrow {
    justify-content: flex-start;
  }

  .right-side-arrow {
    justify-content: flex-end;
  }

  &:hover {
    color: ${(props) => props.theme.colors.secondary};
    transform: translateY(-20px);

    .left-side-arrow {
      transform: translateX(10px);
    }

    .right-side-arrow {
      transform: translateX(-10px);
    }
  }

  @media only screen and (max-width: 640px) {
    font-size: 24px;
    gap: 28px;
    padding: 28px;

    .arrow {
      width: 30px;
    }
  }
`;

export default HomeButton;
