import React from "react";
import styled from "styled-components";
import logo from "../assets/images/logo.svg";
import Profile from "./Profile";
import Avatar from "./Avatar";

function Header({ username, avatar_id, isLoggedIn }) {
  return (
    <HeaderContainer>
      <span className="logo">
        <img src={logo} alt="cloudtech" />
      </span>
      {isLoggedIn === "true" && (
        <span className="profile">
          {/* <Profile $useravatar={avatar_id} username={username} /> */}
          <Avatar $useravatar={avatar_id + ""} />
        </span>
      )}
    </HeaderContainer>
  );
}

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  padding: 32px 0;
  z-index: 9999;
  position: fixed;
  top: 0;

  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);

  /* border: 1px solid red; */

  .profile {
    margin-right: 32px;
  }

  .logo {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: fit-content;
    margin-left: 32px;

    img {
      height: 24px;
    }
  }

  @media only screen and (max-width: 640px) {
    padding: 16px 0;

    .profile {
      margin-right: 16px;
    }

    .logo {
      margin-left: 16px;

      img {
        height: 20px;
      }
    }
  }
`;

export default Header;
