import React from "react";
import styled from "styled-components";
import Header from "./Header";
import failed from "../assets/images/failed.svg";
import Button from "./Button";
import Background from "./Background";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const ResultFail = ({
  username,
  avatar_id,
  score,
  scoreData,
  onTryAgain,
  load,
}) => {
  const tryAgain = () => {
    onTryAgain();
  };

  return (
    <>
      <Background grid={true} objposition="corner" />

      <ResultFailContainer>
        <Header username={username} avatar_id={avatar_id} isLoggedIn="true" />
        <Spin
          spinning={load}
          style={{
            position: "absolute",
            zIndex: 999,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          indicator={
            <LoadingOutlined style={{ fontSize: 50, color: "#E5FE40" }} />
          }
        />
        <img src={failed} alt="fail" style={{ marginTop: "100px" }} />
        <div className="failed">
          <div className="score">
            <h4>your score</h4>
            <p>{score}</p>
          </div>
          <div className="fail-message">
            <h1>Oops! Better luck next time! 🙁</h1>
            <p>
              Your Serverless Smarts were not enough to pass our quiz.
              <br />
              You only missed by {scoreData.passing_score_difference} points.
              <br />
              Try again and conquer the next round!
            </p>
          </div>
        </div>
        <div className="try-again-btn">
          <Button
            label="Try again"
            type="try"
            $filled="true"
            onClick={tryAgain}
          />
        </div>
      </ResultFailContainer>
    </>
  );
};

const ResultFailContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  position: relative;
  z-index: 9;
  padding: 0 20px;
  color: ${(props) => props.theme.colors.white};

  overflow-y: scroll;
  height: 100vh;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  .try-again-btn {
    display: flex;
    margin: 40px 0;
  }

  .failed {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    .score {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: 600;

      h4 {
        font-size: 16px;
        line-height: 19px;
        color: ${(props) => props.theme.colors.grey};
        text-align: center;
        text-transform: uppercase;
      }

      p {
        font-size: 48px;
        line-height: 58px;
        color: ${(props) => props.theme.colors.failure};
      }
    }

    .fail-message {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
      text-align: center;

      h1 {
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
        color: ${(props) => props.theme.colors.primary};
      }

      p {
        font-weight: 400px;
        font-size: 20px;
        line-height: 28px;
        color: ${(props) => props.theme.colors.grey};
        text-align: center;
      }
    }
  }

  @media only screen and (max-width: 640px) {
    .try-again-btn {
      width: 100%;
    }

    .failed {
      .fail-message {
        h1 {
          font-size: 35px;
          line-height: 40px;
        }

        p {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }
`;

export default ResultFail;
