import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Avatar from "./Avatar";

function Profile(props) {
  return (
    <ProfileContainer>
      <Avatar $useravatar={props.$useravatar} />
      <p className="user-name">{props.username}</p>
    </ProfileContainer>
  );
}

const ProfileContainer = styled.div`
  border: 1px solid ${(props) => props.theme.colors.white};
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 4px 20px 4px 4px;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.secondary};
  z-index: 9;
  width: fit-content;

  .user-name {
    color: ${(props) => props.theme.colors.white};
    font-size: 16px;
    font-weight: 700;
    max-width: 300px;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  @media only screen and (max-width: 640px) {
    gap: 14px;
    .user-name {
      font-size: 13px;
      max-width: 120px;
    }
  }
`;

Profile.propTypes = {
  username: PropTypes.string,
};

Profile.defaultProps = {
  username: "userjo.27",
};

export default Profile;
