import React, { useState } from "react";
import styled from "styled-components";
import Header from "./Header";
import FormHeading from "./FormHeading";
import Background from "./Background";
import Button from "./Button";

function Disclaimer({ onGetStartedClick, userInfo }) {
  const [disable, setDisable] = useState(false);

  return (
    <>
      <Background grid={true} objposition="corner" />

      <DisclaimerContainer>
        <Header
          isLoggedIn="true"
          username={userInfo.username}
          avatar_id={userInfo.avatar_id}
        />

        <div className="disclaimer-content-wrapper">
          <FormHeading
            $description=""
            $heading="disclaimer"
            $headingsize="40px"
          />
          <div className="disclaimer-content">
            <p>
              The information contained in this transmission, including any
              attachment(s), may contain privileged and confidential
              information. It constitutes non-public information intended to be
              conveyed only to the designated recipient(s) named above. If you
              are not the designated recipient(s), you are hereby notified that
              any review, dissemination, distribution, or duplication of this
              communication is strictly prohibited. If you are not the
              designated recipient or have received this email by mistake,
              please notify the sender by replying to this email and delete this
              email, including any accompanying attachment(s), from your system.
            </p>
          </div>

          <label className="user-consent">
            <div className="custom-checkbox">
              <input
                type="checkbox"
                checked={disable}
                onChange={() => setDisable(!disable)}
              />
              <span className="geekmark"></span>
            </div>
            <span>I agree to the terms and conditions mentioned above.</span>
          </label>
        </div>

        <QuizStartContainer>
          <span className="quiz">
            <h5>Ready to play and win? 🏆</h5>
            <p>Let's dive into the quiz! Good luck!</p>
          </span>
          <Button
            label="Start the quiz"
            type="start"
            onClick={onGetStartedClick}
            disabled={!disable}
          />
        </QuizStartContainer>
      </DisclaimerContainer>
    </>
  );
}

const DisclaimerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  z-index: 9;
  padding: 0 32px;
  color: ${(props) => props.theme.colors.white};

  height: 100vh;
  width: 100vw;
  overflow-y: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  .disclaimer-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 22px;
    font-size: 18px;
    margin-top: 120px;

    .disclaimer-content {
      border: 1px solid rgba(210, 214, 183, 0.2);
      border-radius: 24px;
      padding: 28px 48px;
      max-width: 865px;
      margin-top: 35px;
      background-color: ${(props) => props.theme.colors.secondary};
      background-image: linear-gradient(
        rgba(225, 250, 63, 0.05),
        rgba(19, 21, 4, 0) 20%
      );

      p {
        font-family: ${(props) => props.theme.fonts.secondary};
        font-weight: 300;
        line-height: 30px;
        color: #c1c4b3;
      }
    }

    .user-consent {
      display: flex;
      align-items: center;
      gap: 12px;
      cursor: pointer;

      input {
        display: none;
      }

      span {
        font-family: ${(props) => props.theme.fonts.primary};
      }

      .custom-checkbox {
        position: relative;
        cursor: pointer;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        .geekmark {
          height: 20px;
          width: 20px;
          background-color: ${(props) => props.theme.colors.primary};
          border-radius: 8px;
          padding: 3px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .custom-checkbox:hover input ~ .geekmark {
        background-color: ${(props) => props.theme.colors.primary};
      }

      .custom-checkbox input:active ~ .geekmark {
        background-color: ${(props) => props.theme.colors.primary};
      }

      .custom-checkbox input:checked ~ .geekmark {
        background-color: ${(props) => props.theme.colors.primary};
        border-color: ${(props) => props.theme.colors.primary} !important;
      }

      .geekmark:after {
        content: "";
        display: none;
      }

      .custom-checkbox input:checked ~ .geekmark:after {
        display: block;
      }

      .custom-checkbox .geekmark:after {
        margin-top: -3px;
        height: 6px;
        width: 12px;
        border: solid ${(props) => props.theme.colors.secondary};
        border-width: 0px 0px 2px 2px;
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
    }
  }

  @media only screen and (max-width: 640px) {
    .disclaimer-content-wrapper {
      font-size: 16px;

      .disclaimer-content {
        padding: 18px 36px;

        p {
          line-height: 28px;
        }
      }
    }
  }

  @media only screen and (max-width: 520px) {
    .disclaimer-content-wrapper {
      font-size: 14px;

      .disclaimer-content {
        padding: 14px 28px;

        p {
          line-height: 26px;
        }
      }
    }
  }
`;

const QuizStartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  z-index: 9;
  margin-top: 60px;

  margin-bottom: 80px;

  .quiz {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: ${(props) => props.theme.fonts.secondary};
    color: ${(props) => props.theme.colors.white};

    h5 {
      font-weight: 500;
      font-size: 20px;
      color: ${(props) => props.theme.colors.primary};
      line-height: 30px;
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
    }
  }

  @media only screen and (max-width: 640px) {
    width: 100%;
  }
`;

export default Disclaimer;
