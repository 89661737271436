import React, { useState } from "react";
import styled, { css } from "styled-components";
import leftGrid from "../assets/images/pattern-left.svg";
import rightGrid from "../assets/images/pattern-right.svg";
import lbLogo from "../assets/images/lb-logo.svg";
import trLogo from "../assets/images/tr-logo.svg";

import obj1 from "../assets/objects/obj-1.svg";
import obj3 from "../assets/objects/obj-3.svg";
import obj4 from "../assets/objects/obj-4.svg";
import obj5 from "../assets/objects/obj-5.svg";
import obj6 from "../assets/objects/obj-6.svg";
import obj7 from "../assets/objects/obj-7.svg";
import obj8 from "../assets/objects/obj-8.svg";
import obj9 from "../assets/objects/obj-9.svg";

function Background(props) {
  const [transformValues, setTransformValues] = useState({
    obj1X: 0,
    obj1Y: 0,
    obj3X: 0,
    obj3Y: 0,
    obj4X: 0,
    obj4Y: 0,
    obj5X: 0,
    obj5Y: 0,
    obj6X: 0,
    obj6Y: 0,
    obj7X: 0,
    obj7Y: 0,
    obj8X: 0,
    obj8Y: 0,
  });

  const handleMouseMove = (e) => {
    const obj1X = (e.pageX * -1) / 30;
    const obj1Y = (e.pageY * -1) / 30;

    const obj3X = e.pageX / 30;
    const obj3Y = e.pageY / 15;

    const obj4X = e.pageX / 30;
    const obj4Y = e.pageY / 15;

    const obj5X = e.pageX / 30;
    const obj5Y = e.pageY / 15;

    const obj6X = e.pageX / 30;
    const obj6Y = e.pageY / 15;

    const obj7X = (e.pageX * -1) / 30;
    const obj7Y = (e.pageY * -1) / 15;

    const obj8X = (e.pageX * -1) / 30;
    const obj8Y = (e.pageY * -1) / 15;

    setTransformValues({
      obj1X,
      obj1Y,
      obj3X,
      obj3Y,
      obj4X,
      obj4Y,
      obj5X,
      obj5Y,
      obj6X,
      obj6Y,
      obj7X,
      obj7Y,
      obj8X,
      obj8Y,
    });
  };

  return (
    <StyledBackground
      {...props}
      onMouseMove={handleMouseMove}
      deviceMotion={handleMouseMove}
    >
      {props.objposition !== "hide" && (
        <>
          <img
            className="obj obj-1"
            src={obj1}
            alt="object"
            style={{
              transform: `translate3d(${transformValues.obj1X}px, ${transformValues.obj1Y}px, 0)`,
            }}
          />
          <img
            className="obj obj-3"
            src={obj3}
            alt="object"
            style={{
              transform: `translate3d(${transformValues.obj3X}px, ${transformValues.obj3Y}px, 0)`,
            }}
          />
          {props.objposition === "corner" && (
            <img
              className="obj obj-4"
              src={obj4}
              alt="object"
              style={{
                transform: `translate3d(${transformValues.obj4X}px, ${transformValues.obj4Y}px, 0)`,
              }}
            />
          )}
          <img
            className="obj obj-5"
            src={obj5}
            alt="object"
            style={{
              transform: `translate3d(${transformValues.obj5X}px, ${transformValues.obj5Y}px, 0)`,
            }}
          />
          <img
            className="obj obj-6"
            src={obj6}
            alt="object"
            style={{
              transform: `translate3d(${transformValues.obj6X}px, ${transformValues.obj6Y}px, 0)`,
            }}
          />
          <img
            className="obj obj-7"
            src={obj7}
            alt="object"
            style={{
              transform: `translate3d(${transformValues.obj7X}px, ${transformValues.obj7Y}px, 0)`,
            }}
          />
          <img
            className="obj obj-8"
            src={obj8}
            alt="object"
            style={{
              transform: `translate3d(${transformValues.obj8X}px, ${transformValues.obj8Y}px, 0)`,
            }}
          />
          <img className="obj obj-9" src={obj9} alt="object" />
        </>
      )}

      {props.topGradient && <span className="top-gradient"></span>}
      {props.bottomgradient && <span className="bottom-gradient"></span>}
      <img className="grid-1" src={leftGrid} alt="grid" />
      <img className="grid-2" src={rightGrid} alt="grid" />
      <img className="lb-logo" src={lbLogo} alt="logo" />
      <img className="tr-logo" src={trLogo} alt="logo" />
    </StyledBackground>
  );
}

const StyledBackground = styled.div`
  background-color: ${(props) => (props.hovered ? "#06091C" : "#0a0b12")};
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  user-select: none;
  overflow: hidden;

  ${(props) =>
    (props.objposition === "normal" || props.objposition === "opaque") &&
    css`
      .obj {
        position: absolute;
        transition: all 0.3s ease-out;
        -webkit-transition: all 0.3s ease-out;
        z-index: 9;
      }

      .obj-1 {
        top: 15%;
        left: 25%;
        transform: ${(props) =>
          props.hovered ? "translate(-5px, -5px)" : "translate(0px, 0px)"};
      }

      .obj-3 {
        top: 85%;
        left: 60%;
        opacity: ${(props) => (props.objposition === "opaque" ? "0.2" : "1")};
        transform: ${(props) =>
          props.hovered ? "translate(15px, -15px)" : "translate(0px, 0px)"};
      }

      .obj-5 {
        top: 80%;
        left: 20%;
        opacity: ${(props) => (props.objposition === "opaque" ? "0.2" : "1")};
        transform: ${(props) =>
          props.hovered ? "translate(-10px, -10px)" : "translate(0px, 0px)"};
      }

      .obj-6 {
        top: 90%;
        left: 85%;
        opacity: ${(props) => (props.objposition === "opaque" ? "0.2" : "1")};
        transform: ${(props) =>
          props.hovered ? "translate(15px, -15px)" : "translate(0px, 0px)"};
      }

      .obj-7 {
        top: 40%;
        left: 10%;
        transform: ${(props) =>
          props.hovered ? "translate(10px, -10px)" : "translate(0px, 0px)"};
      }

      .obj-8 {
        top: 30%;
        left: 75%;
        transform: ${(props) =>
          props.hovered ? "translate(0px, 15px)" : "translate(0px, 0px)"};
      }

      .obj-9 {
        top: 60%;
        right: 0%;
        transform: ${(props) =>
          props.hovered ? "translate(0px, -15px)" : "translate(0px, 0px)"};
      }
    `}

  ${(props) =>
    props.objposition === "corner" &&
    css`
      .obj {
        position: absolute;
        transition: all 0.5s ease-out;
        -webkit-transition: all 0.5s ease-out;
        z-index: 9;
        opacity: 0.3;
      }

      .obj-1 {
        top: 25%;
        right: -0.5%;
      }

      .obj-3 {
        top: -0.6%;
        left: 60%;
      }

      .obj-4 {
        bottom: -1%;
        right: 10%;
      }

      .obj-5 {
        top: 62%;
        left: 5%;
      }

      .obj-6 {
        top: 75%;
        left: 25%;
      }

      .obj-7 {
        top: 20%;
        left: -1%;
      }

      .obj-8 {
        display: none;
      }

      .obj-9 {
        top: 60%;
        right: 0%;
      }
    `}

  .obj {
    opacity: 0.5;
  }

  .top-gradient {
    position: absolute;
    width: 80vw;
    height: 20vh;
    border-radius: 50%;
    top: -20%;
    left: 50%;
    transform: translate(-50%, -60%);
    background-color: #684ff4;
    filter: blur(200px);
  }

  .bottom-gradient {
    position: absolute;
    width: ${(props) => (props.hovered ? "90vw" : "30vw")};
    height: ${(props) => (props.hovered ? "30vh" : "20vh")};
    border-radius: 50%;
    top: ${(props) => (props.hovered ? "100%" : "110%")};
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${(props) =>
      props.hovered ? "radial-gradient(#684FF4, #684ff4, #000000)" : "#e5fe40"};
    filter: blur(150px);
    opacity: ${(props) => (props.hovered ? "0.7" : "0.3")};
    transition: width 0.5s ease-out, height 0.5s ease-out, top 0.5s ease-out,
      background 0.5s ease-out, opacity 0.5s ease-out;

    -webkit-transition: width 0.5s ease-out, height 0.5s ease-out,
      top 0.5s ease-out, background 0.5s ease-out, opacity 0.5s ease-out;
  }

  .grid-1 {
    position: absolute;
    height: 90vh;
    top: 0;
    left: 0;
    opacity: ${(props) =>
      props.topGradient || props.hovered || props.grid ? "1" : "0"};
    transition: opacity 0.5s ease-out;
    -webkit-transition: opacity 0.5s ease-out;
  }

  .grid-2 {
    position: absolute;
    height: 90vh;
    bottom: 0;
    right: 0;
    opacity: ${(props) =>
      props.topGradient || props.hovered || props.grid ? "1" : "0"};
    transition: opacity 0.5s ease-out;
  }

  .lb-logo {
    position: absolute;
    left: -10px;
    bottom: -10px;
    transform: ${(props) =>
      props.hovered ? "translate(10px, -10px)" : "translate(0px, 0px)"};
    transition: transform 0.5s ease-out;
    -webkit-transition: transform 0.5s ease-out;
  }

  .tr-logo {
    position: absolute;
    top: -10px;
    right: -10px;
    transform: ${(props) =>
      props.hovered ? "translate(-10px, 10px)" : "translate(0px, 0px)"};
    transition: transform 0.5s ease-out;
    -webkit-transition: transform 0.5s ease-out;
  }

  @media only screen and (max-width: 768px) {
    .lb-logo,
    .tr-logo {
      height: 120px;
    }

    .grid-1,
    .grid-2 {
      height: 70vh;
    }
  }

  @media only screen and (max-width: 640px) {
    .obj,
    .lb-logo,
    .tr-logo {
      display: none;
    }

    .grid-1 {
      opacity: 0;
    }

    .grid-2 {
      opacity: 1;
    }

    .bottom-gradient {
      opacity: 1;
      background: radial-gradient(#684ff4, #684ff4, #000000);
      height: 45vh;
      width: 90vw;
    }
  }
`;

export default Background;
