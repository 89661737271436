import React, { useState } from "react";
import styled from "styled-components";
import HomeButton from "./HomeButton";
import FormHeading from "./FormHeading";
import Header from "./Header";
import Background from "./Background";

const Intro = ({ onGetStartedClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <>
      <Background
        bottomgradient={true}
        hovered={isHovered}
        objposition="normal"
      />
      <IntroContainer>
        <Header />
        <div className="intro-content">
          <p className="intro-description">hello there!</p>
          <p className="intro-main-heading">welcome to the quiz!</p>
        </div>
        <HomeButton
          onClick={onGetStartedClick}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        />
      </IntroContainer>
    </>
  );
};

const IntroContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 48px;
  position: relative;
  z-index: 9;
  padding: 0 10px;

  .intro-content {
    text-transform: uppercase;
    letter-spacing: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    z-index: 9;

    .intro-description {
      color: ${(props) => props.theme.colors.primary};
      font-size: 24px;
      font-weight: 500;
      text-align: center;
    }

    .intro-main-heading {
      color: ${(props) => props.theme.colors.white};
      font-size: 48px;
      font-weight: 700;
      text-align: center;
    }
  }

  @media only screen and (max-width: 640px) {
    margin-top: -150px;

    .intro-content {
      gap: 8px;

      .intro-description {
        font-size: 16px;
      }

      .intro-main-heading {
        font-size: 32px;
      }
    }
  }
`;

export default Intro;
