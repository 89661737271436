import React, { useState, useEffect } from "react";
import styled from "styled-components";
import FormHeading from "./FormHeading";
//import { instructionList } from "../assets/InstructionList";
import cup from "../assets/images/cup.svg";
import Button from "./Button";
import Header from "./Header";
import Background from "./Background";
import { GET_QUESTIONS_ENDPOINT } from "../util/ApiEndpoint";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import ins1 from "../assets/images/share.svg";
import ins3 from "../assets/images/forward.svg";
import ins4 from "../assets/images/tag-cross.svg";
import { encrypt } from "../util/Encrypt";

const Instructions = ({
  userInfo,
  onContinue,
  setQuestions,
  onGetStartedClick,
}) => {
  const [disable, setDisable] = useState(false);
  const [response, setResponse] = useState(false);
  const [timer, setTimer] = useState(0);
  const [loading, setLoading] = useState(false);

  const instructionList = [
    {
      id: 1,
      image: ins1,
      content: `5 exciting questions with ${timer} seconds each.`,
    },
    {
      id: 3,
      image: ins3,
      content:
        "Selecting an answer will lead you to the next question."
    },
    {
      id: 4,
      image: ins4,
      content:
        "Going back is not an option, so stay sharp!",
    },
  ]

  useEffect(() => {
    getQuestions();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getQuestions = async () => {
    await fetch(GET_QUESTIONS_ENDPOINT, {
      method: "post",
      body: encrypt(JSON.stringify(userInfo)),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setTimer(data.questions_timer);
        setQuestions(data);
        setResponse(true);
        setLoading(true);
      })
      .catch((err) => {
        setDisable(false);
        console.log("Error--", err);
      });
  };

  const openTerms = () => {
    window.open(process.env.REACT_APP_DOC_URL, "_blank", "noreferrer");
  };

  return (
    <>
      <Background grid={true} objposition="corner" />
      
      <Spin
        spinning={!loading}
        style={{
          zIndex: 999,
          top: "30%",
          left: "50%",
          position: 'absolute',
          transform: "translate(-50%, -50%)",
        }}
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 50,
              color: "#E5FE40",
            }}
          />
        }
      />

      <InstructionWrapper>
        <Header
          isLoggedIn="true"
          username={userInfo.username}
          avatar_id={userInfo.avatar_id}
        />

        <FormHeading
          $description="instructions"
          $heading="your go-to guide"
          $headingsize="16px"
          $descriptionsize="14px"
          style={{ marginTop: "130px" }}
        />

        {loading && (<>
          <InstructionContainer>
            <div className="instruction-section">
              <span className="cup d-cup">
                <img src={cup} alt="cup" />
              </span>
              <span className="win">
                <h5 className="win-heading">
                  <span className="cup m-cup">
                    <img src={cup} alt="cup" />
                  </span>
                play to win amazing goodies
              </h5>
                <p>
                  By attempting our quiz, you can bring your score to our booth and receive a token to play the claw machine.
              </p>
              </span>
            </div>
            <div className="instruction-points">
              {instructionList.map((instruction, index) => (
                <span className="point" key={index}>
                  <img src={instruction.image} alt="point" />
                  <p>{instruction.content}</p>
                </span>
              ))}
            </div>
          </InstructionContainer>

          <div className="disclaimer-content-wrapper">
            <label className="user-consent">
              <div className="custom-checkbox">
                <input
                  type="checkbox"
                  checked={disable}
                  onChange={() => setDisable(!disable)}
                />
                <span className="geekmark"></span>
              </div>
              <span>
                I agree to the{" "}
                <u onClick={() => openTerms()} style={{ color: "dodgerblue" }}>
                  terms and conditions
              </u>{" "}
              mentioned of serverless smarts quiz.
            </span>
            </label>
          </div>
          <QuizStartContainer>
            <span className="quiz">
              <h5>Ready to play and win? 🏆</h5>
              <p>Let's dive into the quiz! Good luck!</p>
            </span>
            <Button
              label="Start the quiz"
              type="start"
              onClick={onGetStartedClick}
              disabled={!(disable && response)}
            />
          </QuizStartContainer> </>)}
      </InstructionWrapper>
    </>
  );
};

const InstructionContainer = styled.div`
  color: white;
  display: flex;
  gap: 20px;
  z-index: 9;

  .instruction-points {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: fit-content;

    .point {
      display: flex;
      gap: 20px;
      align-items: center;
      padding: 24px 20px;
      border-radius: 20px;
      border: 1px solid #d2d6b729;
      line-height: 26px;
      font-weight: 300;
      font-size: 16px;
      background-color: ${(props) => props.theme.colors.secondary};
      background-image: linear-gradient(
        rgba(225, 250, 63, 0.05),
        rgba(19, 21, 4, 0) 20%
      );
      font-family: ${(props) => props.theme.fonts.secondary} !important;
    }
  }

  .instruction-section {
    width: fit-content;
    padding: 20px 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 20px;
    border: 1px solid #d2d6b729;
    background-color: ${(props) => props.theme.colors.secondary};
    background-image: linear-gradient(
      rgba(225, 250, 63, 0.05),
      rgba(19, 21, 4, 0) 20%
    );

    .cup {
      padding: 12px;
      border: 2px solid #e5fe401a;
      border-radius: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      height: fit-content;
    }

    .win {
      display: flex;
      flex-direction: column;
      gap: 4px;
      font-family: ${(props) => props.theme.fonts.secondary};
      font-size: 16px;

      .win-heading {
        font-weight: 600;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.primary};
        line-height: 24px;

        .m-cup {
          display: none;
        }
      }

      p {
        font-weight: 300;
        width: 225px;
        line-height: 26px;
      }
    }
  }

  @media only screen and (max-width: 640px) {
    flex-direction: column;

    .instruction-points {
      width: 100%;
    }

    .instruction-section {
      .d-cup {
        display: none;
      }

      .win {
        gap: 8px;

        .win-heading {
          display: flex;
          align-items: center;
          gap: 12px;

          .m-cup {
            display: flex;
          }
        }
      }

      .win p {
        width: 100%;
      }
    }
  }
`;

const InstructionWrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
  position: relative;
  height: 100vh;
  overflow: scroll;
  padding: 0 10px;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  .continue-btn {
    margin-top: 72px;
  }

  .disclaimer-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    color: white;

    .user-consent {
      display: flex;
      align-items: center;
      gap: 12px;
      cursor: pointer;

      input {
        display: none;
      }

      span {
        font-family: ${(props) => props.theme.fonts.primary};
      }

      .custom-checkbox {
        position: relative;
        cursor: pointer;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        .geekmark {
          height: 18px;
          width: 18px;
          background-color: ${(props) => props.theme.colors.secondary};
          outline: 2px solid ${(props) => props.theme.colors.primary};
          border-radius: 7px;
          padding: 3px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .custom-checkbox input:active ~ .geekmark {
        background-color: ${(props) => props.theme.colors.primary};
      }

      .custom-checkbox input:checked ~ .geekmark {
        background-color: ${(props) => props.theme.colors.primary};
        border-color: ${(props) => props.theme.colors.primary} !important;
      }

      .geekmark:after {
        content: "";
        display: none;
      }

      .custom-checkbox input:checked ~ .geekmark:after {
        display: block;
      }

      .custom-checkbox .geekmark:after {
        margin-top: -3px;
        height: 6px;
        width: 12px;
        border: solid ${(props) => props.theme.colors.secondary};
        border-width: 0px 0px 2px 2px;
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
    }
  }

  @media only screen and (max-width: 640px) {
    .disclaimer-content-wrapper {
      font-size: 16px;

      .user-consent {
        display: flex;
        align-items: flex-start;
      }
    }
  }
`;

const QuizStartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  z-index: 9;
  // margin-top: 60px;

  margin-bottom: 80px;

  .quiz {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: ${(props) => props.theme.fonts.secondary};
    color: ${(props) => props.theme.colors.white};

    h5 {
      font-weight: 500;
      font-size: 20px;
      color: ${(props) => props.theme.colors.primary};
      line-height: 30px;
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
    }
  }

  @media only screen and (max-width: 640px) {
    width: 100%;
  }
`;

export default Instructions;
