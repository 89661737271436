import {JSEncrypt} from "jsencrypt";
let forge = require('node-forge');

// Hybrid encryption using both symmetric (AES-256) asymmetric (RSA) approach
export const encrypt = (dataToEncrypt) => {
    // Symmetric key encryption: generating key and iv 
    let key = forge.random.getBytesSync(32);
    let keyHex = forge.util.bytesToHex(key);
    let iv = forge.random.getBytesSync(16); // not 32
    let ivHex = forge.util.bytesToHex(iv);

    // Use AES encryption to encrypt the payload
    let cipher = forge.cipher.createCipher('AES-CBC', key);
    cipher.start({iv: iv});
    cipher.update(forge.util.createBuffer(dataToEncrypt));
    cipher.finish();
    let encrypted = cipher.output;

    // Use RSA encryption to encrypt the AES key and iv
    let encrypt = new JSEncrypt();
    encrypt.setPublicKey(process.env.REACT_APP_PUBLIC_KEY);
    let encryptedKey = encrypt.encrypt(keyHex);
    let encryptedIv = encrypt.encrypt(ivHex);

    let dataObject = {"encryptedData": encrypted.toHex(), "encryptedKey": encryptedKey, "encryptedIv": encryptedIv };
    return JSON.stringify(dataObject);
}