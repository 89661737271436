import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { avatarList, user1 } from "../assets/avatars/AvatarList";

function Avatar(props) {
  const userAvatarSrc = avatarList[props.$useravatar] || user1;
  return (
    <AvatarContainer {...props}>
      <img src={userAvatarSrc} alt="avatar" />
    </AvatarContainer>
  );
}

const AvatarContainer = styled.span`
  height: ${(props) => (props.size ? props.size : "40px")};
  width: ${(props) => (props.size ? props.size : "40px")};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 9;
  object-fit: cover;

  img {
    height: ${(props) => (props.size ? props.size : "40px")};
    width: ${(props) => (props.size ? props.size : "40px")};
  }

  @media only screen and (max-width: 640px) {
    height: ${(props) => (props.size === "40px" ? "30px" : "46px")};
    width: ${(props) => (props.size === "40px" ? "30px" : "46px")};
    img {
      height: ${(props) => (props.size === "40px" ? "30px" : "46px")};
      width: ${(props) => (props.size === "40px" ? "30px" : "46px")};
    }
  }
`;

Avatar.propTypes = {
  size: PropTypes.string,
  $useravatar: PropTypes.string,
  $usercolor: PropTypes.string,
};

Avatar.defaultProps = {
  size: "40px",
  $useravatar: "1",
};

export default Avatar;
