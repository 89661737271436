import React, { useState } from "react";
import styled from "styled-components";
import { AnimatePresence } from "framer-motion";
import moment from "moment-timezone";
import Quiz from "../components/Quiz";
import Intro from "../components/Intro";
// import UserFullName from "../components/UserFullName";
// import UserJobTitle from "../components/UserJobTitle";
import UserName from "../components/UserName";
import ResultPass from "../components/ResultPass";
import ResultFail from "../components/ResultFail";
import FallBack from "../components/Fallback";
import Instructions from "../components/Instructions";
// import UserCompany from "../components/UserCompany";
import UserEmail from "../components/UserEmail";
import Disclaimer from "../components/Disclaimer";
import UserDetails from "../components/UserDetails";
import { GET_QUESTIONS_ENDPOINT } from "../util/ApiEndpoint";
import { encrypt } from "../util/Encrypt";

const NEXT_COMPONENT = {
  intro: "user_email",
  user_email: "user_details",
  user_details: "username",
  // user_full_name: "user_company",
  // user_company: "user_job_title",
  // user_job_title: "username",
  username: "instructions",
  instructions: "quiz",
  quiz_pass: "result_pass",
  quiz_fail: "result_fail",
  try_again: "quiz",
};

const Main = () => {
  const [displayView, setDisplayView] = useState("intro");
  const [userInformation, setUserInformation] = useState({});
  const [questions, setQuestions] = useState([]);
  const [quizScore, setQuizScore] = useState({});
  const [loading, setLoading] = useState(false);

  const setUserDetails = (userData, email) => {
    if (Object.keys(userData).length === 0) {
      // New User
      setUserInformation({
        email: email,
        name: "",
        username: "",
        job_title: "",
        avatar_id: "1",
        company: "",
      });
    } else {
      // Existing user
      setUserInformation(userData);
    }

    setDisplayView(NEXT_COMPONENT["user_email"]);
  };

  const updateUserInformation = (field_name, value, next_comp) => {
    let userInfo = { ...userInformation };

    if (field_name === "user_details") {
      userInfo["name"] = value.name;
      userInfo["company"] = value.company;
      userInfo["job_title"] = value.role;
    } else {
      userInfo[field_name] = value;
    }
    setUserInformation(userInfo);
    setDisplayView(NEXT_COMPONENT[next_comp]);
  };

  const updateUsername = (username, avatar_id) => {
    let userInfo = { ...userInformation };
    userInfo.username = username;
    userInfo.avatar_id = avatar_id;
    setUserInformation(userInfo);
    setDisplayView(NEXT_COMPONENT["username"]);
  };

  const generateValidityTime = () => {
    const time = quizScore.timestamp
      ? quizScore.timestamp
      : new Date().toUTCString();
    const utcTime = moment.utc(time);
    let validityTime = moment
      .tz(utcTime, "America/Los_Angeles")
      .add(2, "hours")
      .format("MMM DD, hh:mm A");
    return validityTime;
  };

  // Identify which page to laod
  const resultChecking = (data) => {
    setQuizScore(data);
    if (data && data.result === "Failed") {
      setDisplayView(NEXT_COMPONENT["quiz_fail"]);
    } else {
      setDisplayView(NEXT_COMPONENT["quiz_pass"]);
    }
  };

  const onTryAgain = () => {
    setLoading(true);
    getQuestions();
  };

  const getQuestions = async () => {
    await fetch(GET_QUESTIONS_ENDPOINT, {
      method: "post",
      body: encrypt(JSON.stringify(userInformation)),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setQuestions(data);
        setLoading(false);
        setDisplayView(NEXT_COMPONENT["try_again"]);
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error--", err);
      });
  };

  return (
    <MainContainer>
      <AnimatePresence mode="wait">
        {
          {
            intro: (
              <Intro
                onGetStartedClick={() => {
                  setDisplayView(NEXT_COMPONENT["intro"]);
                }}
              />
            ),
            user_email: <UserEmail setUserDetails={setUserDetails} />,
            // user_full_name: (
            //   <UserFullName
            //     fullName={
            //       userInformation && userInformation.name
            //         ? userInformation.name
            //         : ""
            //     }
            //     updateUserInformation={updateUserInformation}
            //   />
            // ),
            // user_company: (
            //   <UserCompany
            //     userCompany={
            //       userInformation && userInformation.company
            //         ? userInformation.company
            //         : ""
            //     }
            //     updateUserInformation={updateUserInformation}
            //   />
            // ),
            // user_job_title: (
            //   <UserJobTitle
            //     userJobTitle={
            //       userInformation && userInformation.job_title
            //         ? userInformation.job_title
            //         : ""
            //     }
            //     updateUserInformation={updateUserInformation}
            //   />
            // ),
            user_details: (
              <UserDetails
                userInfo={userInformation}
                updateUserInformation={updateUserInformation}
              />
            ),
            username: (
              <UserName
                userInfo={userInformation}
                updateUsername={updateUsername}
              />
            ),
            instructions: (
              <Instructions
                userInfo={userInformation}
                onGetStartedClick={() => {
                  setDisplayView(NEXT_COMPONENT["instructions"]);
                }}
                setQuestions={(data) => setQuestions(data)}
              />
            ),
            disclaimer: (
              <Disclaimer
                onGetStartedClick={() => {
                  setDisplayView(NEXT_COMPONENT["disclaimer"]);
                }}
                userInfo={userInformation}
              />
            ),
            quiz: (
              <Quiz
                userInfo={userInformation}
                questions={questions.questions}
                timer={questions.questions_timer}
                resultChecking={resultChecking}
              />
            ),
            result_pass: (
              <ResultPass
                username={userInformation.username}
                score={quizScore.score}
                onGetStartedClick={() => {
                  setDisplayView(NEXT_COMPONENT["result_pass"]);
                }}
                avatar_id={userInformation.avatar_id}
                time={generateValidityTime()}
                onTryAgain={onTryAgain}
                load={loading}
              />
            ),
            result_fail: (
              <ResultFail
                username={userInformation.username}
                score={quizScore.score}
                scoreData={quizScore}
                onGetStartedClick={() => {
                  setDisplayView(NEXT_COMPONENT["result_fail"]);
                }}
                avatar_id={userInformation.avatar_id}
                onTryAgain={onTryAgain}
                load={loading}
              />
            ),
            fallback: <FallBack />,
          }[displayView]
        }
      </AnimatePresence>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export default Main;
