import React from "react";
import styled from "styled-components";
import nextButtonImg from "../assets/images/next-button.svg";

function NextButton(props) {
  return (
    <NextButtonContainer onClick={props.onClick}>
      <img src={nextButtonImg} alt="next" />
    </NextButtonContainer>
  );
}

const NextButtonContainer = styled.button`
  border: 1px solid ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.secondary};
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  z-index: 999;
  position: fixed;
  left: 50%;
  bottom: 10%;
  transform: translate(-50%, -50%);

  &:hover {
    background-color: ${(props) => props.theme.colors.white};
    cursor: pointer;
  }

  img {
    mix-blend-mode: difference;
  }
`;

export default NextButton;
