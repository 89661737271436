import { useState } from "react";
import styled from "styled-components";
import { profanityCheck } from "../util/ProfanityCheck";
import Input from "./Input";
import { GET_USER_DETAILS_ENDPOINT } from "../util/ApiEndpoint";
import { short_title } from "../contents/Content";
import { isEmpty } from "../util/Functions";
import FormHeading from "./FormHeading";
import NextButton from "./NextButton";
import Header from "./Header";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import Background from "./Background";
import Button from "./Button";

const re =
  /^(([^<>()[\].;:\s@"]+(.[^<>()[\].;:\s@"]+)*)|(".+"))@(([^<>()[\].;:\s@"]+\.)+[^<>()[\].;:\s@"]{2,})$/i;

const UserEmail = ({ setUserDetails }) => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const emailValidation = (email) => {
    const isMatch = re.test(email);
    return isMatch;
  };

  const onInputChange = (e) => {
    if (e.target.value === "") {
      setErrorMessage(null);
    }
    setEmail(e.target.value);
  };

  const getUserDetails = async () => {
    setLoading(true);
    await fetch(`${GET_USER_DETAILS_ENDPOINT}?email=${email}`, {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setUserDetails(data, email);
      })
      .catch((err) => {
        setErrorMessage(
          "Unable to fetch user details !!! Please try again after some time."
        );
        setLoading(false);
        console.log("Error--", err);
      });
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter" || e.type === "click") {
      const isProfane = profanityCheck(email);
      const isValidEmail = emailValidation(email);
      if (isEmpty(email)) {
        return setErrorMessage("Please enter a valid email address");
      }
      if (isProfane) {
        // Profanity detected
        return setErrorMessage(
          "Profane words not allowed. Please use different email to proceed."
        );
      }
      if (!isValidEmail) {
        return setErrorMessage("Please enter a valid email address.");
      }

      setErrorMessage(null);
      getUserDetails();
    }
  };

  return (
    <>
      <Background grid={true} topGradient={true} objposition="normal" />

      <UserEmailContainer>
        <Header isLoggedIn="false" />

        <FormHeading
          $description={short_title}
          $heading="let's start with your email"
          $descriptionsize="14px"
          $headingsize="40px"
        />

        <div className="sub-heading">
          Receive an exclusive, <span>LinkedIn-shareable badge for a score above 600! 🚀</span>
        </div>

        <Spin
          spinning={loading}
          style={{
            // position: "absolute",
            zIndex: 999,
          }}
          indicator={
            <LoadingOutlined style={{ fontSize: 50, color: "#E5FE40" }} />
          }
        />

        {!loading && (
          <>
            <Input
              value={email}
              onChange={onInputChange}
              onKeyDown={onKeyDown}
              placeholder="Enter Your Work Email"
              disabled={loading}
            />

            <div style={{ color: "#F85C5C" }}>
              {errorMessage ? errorMessage : null}
            </div>
            <div className="btn-container">
              <Button type="continue" label="Continue" onClick={onKeyDown} />
            </div>
          </>
        )}
      </UserEmailContainer>
    </>
  );
};

const UserEmailContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 48px;
  z-index: 9;
  padding: 0 20px;

  .btn-container {
    width: fit-content;
  }

  .sub-heading {
    color: #FFF;
    text-align: center;
    font-size: 20px;
    font-weight: 300;
    line-height: 22px;
    align-self: stretch;
  }

  @media only screen and (max-width: 640px) {
    margin-top: -150px;

    .btn-container {
      width: calc(100% - 32px);
      position: fixed;
      bottom: 20px;
    }

    .sub-heading {
      font-size: 15px;
      span {
        display: block;
      }
    }
  }

`;

export default UserEmail;
