import React, { useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

function Input(props) {
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <InputContainer {...props}>
      <input
        ref={inputRef}
        type={props.type}
        placeholder={props.placeholder}
        maxLength={props.maxlength || ""}
        autoComplete={props.autoComplete}
        disabled={props.disabled}
        {...props.field}
        {...props.inputProps}
        onChange={props.onChange}
        value={props.value}
        onBlur={props.onBlur}
        onKeyDown={props.onKeyDown}
      />
      {props.showentermessage !== "false" ? (
        <span className="instruction">Please press enter to proceed</span>
      ) : null}

      {props.meta && props.meta.error && props.meta.touched && (
        <span className="error">{props.meta.error}</span>
      )}
    </InputContainer>
  );
}

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  z-index: 9;

  .instruction {
    color: #727753;
    font-size: 16px;
  }

  ${(props) => textInput}
`;

const textInput = css`
  input {
    background-color: ${(props) => props.theme.colors.primary};
    padding: 34px 72px;
    border-radius: 16px;
    outline: 8px solid rgba(243, 252, 181, 0.2);
    border: 0;
    color: ${(props) => props.theme.colors.black};
    font-weight: 500;
    font-size: 26px;
    transition: 0.3s ease-out;
    -webkit-transition: 0.3s ease-out;
    text-align: center;
    width: ${(props) => (props.width === "full" ? "100%" : "fit-content")};

    &::placeholder {
      font-size: 26px;
      font-weight: 600;
      text-align: center;
      color: #afc235;
    }

    &::-ms-input-placeholder {
      font-size: 26px;
      font-weight: 600;
      text-align: center;
      color: #afc235;
    }

    &:hover,
    &:focus {
      outline-width: 14px;
    }
  }

  @media only screen and (max-width: 640px) {
    input {
      font-size: 20px;
      padding: 30px 60px;
      outline-width: 6px;
      width: fit-content;

      &::placeholder {
        font-size: 20px;
      }

      &::-ms-input-placeholder {
        font-size: 20px;
      }
    }

    &:hover,
    &:focus {
      outline-width: 8px;
    }
  }
`;

Input.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
};

Input.defaultProps = {
  type: "text",
  placeholder: "Enter Your Name",
  autoComplete: "on",
  disabled: false,
};

export default Input;
