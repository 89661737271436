const theme = {
  colors: {
    primary: "#E5FE40",
    secondary: "#090A11",
    success: "#089F7B",
    failure: "#F87171",
    error: "#EF4444",
    grey: "#9AA1B0",
    white: "#FFFFFF",
    black: "#000000",
  },
  fonts: {
    primary: "'Figtree', sans-serif",
    secondary: "'Poppins', sans-serif",
  },
};

export default theme;
