import user1 from "./user-1.png";
import user2 from "./user-2.png";
import user3 from "./user-3.png";
import user4 from "./user-4.png";
import user5 from "./user-5.png";
import user6 from "./user-6.png";
import user7 from "./user-7.png";
import user8 from "./user-8.png";
import user9 from "./user-9.png";
import user10 from "./user-10.png";

const avatarList = {
  1: user1,
  2: user2,
  3: user3,
  4: user4,
  5: user5,
  6: user6,
  7: user7,
  8: user8,
  9: user9,
  10: user10,
};

const userAvatarsOptions = [
  {
    id: "1",
    avatar: user1,
  },
  {
    id: "2",
    avatar: user2,
  },
  {
    id: "3",
    avatar: user3,
  },
  {
    id: "4",
    avatar: user4,
  },
  {
    id: "5",
    avatar: user5,
  },
  {
    id: "6",
    avatar: user6,
  },
  {
    id: "7",
    avatar: user7,
  },
  {
    id: "8",
    avatar: user8,
  },
  {
    id: "9",
    avatar: user9,
  },
  {
    id: "10",
    avatar: user10,
  },
];

export { avatarList, userAvatarsOptions, user1 };
